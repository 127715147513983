import { graphql } from "gatsby";
import React from "react";
import PageWrapper from "../components/PageWrapper";
import ProductsOverview from "../sections/landing2/Content2";
import Tiles from "../sections/landing1/Feature";
import CTA from "../sections/landing2/CTA";
import Hero from "../sections/landing4/Hero";
import Content16 from "../sections/landing6/Content1";
import Facts from "../sections/landing7/Fact";
import CNC from "../sections/landing8/Content2";
import ImageGrid from "../sections/PortfolioGridThree";
import BlogLatest from "../sections/blog/BlogLatest";

const LandingPage3 = ({
  data
}: any) => {
  const page = data.landingPage.edges[0].node;
  const {
    numbersHeader,
    products,
    k2Header,
    k2Description,
    k2Images,
    number1,
    number2,
    number3,
  } = page;
  const cnc = {
    header: k2Header,
    description: k2Description,
    images: k2Images,
  };
  const numbers = [number1, number2, number3];

  return (
    <>
      <PageWrapper>
        <Hero hero={page.hero} slogan={page.slogan} />
        <Facts numbers={numbers} numbersHeader={numbersHeader} />
        <ProductsOverview products={products} />
        <CNC content={cnc} />
        <CTA />
        <Content16 />
        <Tiles />
        <ImageGrid />
        <BlogLatest />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default LandingPage3;

export const query = graphql`
  query {
    landingPage: allGraphCmsPage(
      filter: { slug: { eq: "landing-page" }, locale: { eq: de } }
    ) {
      edges {
        node {
          # HERO
          hero
          slogan
          # NUMBERS
          numbersHeader
          number1 {
            count
            description
          }
          number2 {
            count
            description
          }
          number3 {
            count
            description
          }
          # PRODUCTS
          products {
            ... on GraphCMS_ProductCard {
              id
              header
              buttonText
              slogan {
                html
              }
              image {
                id
                url
              }
            }
          }
          # CNC
          k2Header
          k2Description {
            html
          }
          k2Images {
            url
          }
        }
      }
    }
  }
`;
