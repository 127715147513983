import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";


// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/l4-con... Remove this comment to see the full error message
import imgContent1 from "../../assets/image/jpeg/l4-content-3-img-1.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/l4-con... Remove this comment to see the full error message
import imgContent2 from "../../assets/image/jpeg/l4-content-3-img-2.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({
  theme
}: any) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content2 = ({
  content
}: any) => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center">
          <Col xl="7" lg="10" md="9">
            <div className="text-center">
              <Title>{content.header}</Title>
              <Text dangerouslySetInnerHTML={{ __html: content.description.html }} />
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4" sm="5" className="mb-4 ">
            <ContentImg>
              <img src={imgContent1} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
          <Col lg="8" sm="7" className="mb-4">
            <ContentImg>
              <img src={imgContent2} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
