import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/l2-con... Remove this comment to see the full error message
import imgContentBig from "../../assets/image/jpeg/l2-content3-big.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l2-cont... Remove this comment to see the full error message
import imgOval from "../../assets/image/png/l2-content3-oval.png";
import TextWrapper from "../../components/Core/TextWrapper";

const ImageBottom = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({
  theme
}: any) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: 52%;
  left: -3%;
  z-index: 2;
  border-radius: 500px;
  overflow: hidden;
  max-width: 50%;

  @media ${device.md} {
    max-width: 100%;
  }
  @media ${device.lg} {
    left: 6%;
  }
  @media ${device.xl} {
    left: 8%;
  }
`;

const ImageRight = styled(Box)`
  img {
    box-shadow: ${({
  theme
}: any) => `0 52px 54px ${theme.colors.shadow}`};
    border-radius: 10px;
  }
`;

const Content2 = ({
  products
}: any) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section>
      <Container>
        {products.map((product: any, i: any) => (
          <Row className="align-items-center py-5">
            <Col
              lg="6"
              md="10"
              className={`${i % 2 !== 0 ? "order-lg-1" : "order-lg-2"} b-4`}
            >
              <div className="position-relative text-lg-right">
                <ImageRight
                  className=""
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                >
                  <img src={imgContentBig} alt="" className="img-fluid" />
                </ImageRight>
                <ImageBottom
                  bg="light"
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <img src={imgOval} alt="" className="img-fluid" />
                </ImageBottom>
              </div>
            </Col>
            <Col
              lg="6"
              className={`${
                i % 2 !== 0 ? "order-lg-2" : "order-lg-1"
              } mt-5 mt-lg-0`}
            >
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
                  <Title variant="h4">{product.header}</Title>
                  <TextWrapper
                    variant="small"
                    mb={4}
                    mt={4}
                    dangerouslySetInnerHTML={{ __html: product.slogan.html }}
                  />

                  <a href={`/`}>
                    <Button bg="holzDarkGreen" mt={4}>
                      {product.buttonText}
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center py-5">
          <a href={"missing link"}>
            <Button mt={4}>missing</Button>
          </a>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
