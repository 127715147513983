import React from 'react';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from 'styled-components/macro';

export const Button = styled.button.attrs((props: any) => ({
  // we can define static props
  className: 'btn'
}))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${(props: any) => props.sizeX ? props.sizeX : null};
  height: ${(props: any) => props.sizeY ? props.sizeY : null};
  font-size: ${(props: any) => props.fontSize ? props.fontSize : null};
  letter-spacing: ${(props: any) => props.letterSpacing};
  padding-left: ${(props: any) => props.pl ? props.pl : '10px'};
  padding-right: ${(props: any) => props.pr ? props.pr : '10px'};
  text-transform: ${(props: any) => props.textTransform ? props.textTransform : 'capitalized'};
  border-radius: ${(props: any) => props.radius ? props.radius : '5px'};
  margin-top: ${(props: any) => props.mt};
  margin-bottom: ${(props: any) => props.mb};
  color: ${(props: any) => props.textColor};
  background: ${(props: any) => props.backgroundColor};
  border-radius: ${(props: any) => props.rounded ? '500px' : null};
  @media (min-width: 480px) {
    margin-top: ${(props: any) => props.mtXS};
    margin-bottom: ${(props: any) => props.mbXS};
    min-width: ${(props: any) => props.sizeXXS};
    height: ${(props: any) => props.sizeYXS ? props.sizeYXS : null};
    font-size: ${(props: any) => props.fontSizeXS ? props.fontSizeXS : null};
  }
  @media (min-width: 575px) {
    margin-top: ${(props: any) => props.mtSM};
    margin-bottom: ${(props: any) => props.mbSM};
    min-width: ${(props: any) => props.sizeXSM};
    height: ${(props: any) => props.sizeYSM ? props.sizeYSM : null};
    font-size: ${(props: any) => props.fontSizeSM ? props.fontSizeSM : null};
  }
  @media (min-width: 768px) {
    margin-top: ${(props: any) => props.mtMD};
    margin-bottom: ${(props: any) => props.mbMD};
    min-width: ${(props: any) => props.sizeXMD};
    height: ${(props: any) => props.sizeYMD ? props.sizeYMD : null};
    font-size: ${(props: any) => props.fontSizeMD ? props.fontSizeMD : null};
  }
  @media (min-width: 992px) {
    margin-top: ${(props: any) => props.mtLG};
    margin-bottom: ${(props: any) => props.mbLG};
    min-width: ${(props: any) => props.sizeXLG};
    height: ${(props: any) => props.sizeYLG ? props.sizeYLG : null};
    font-size: ${(props: any) => props.fontSizeLG ? props.fontSizeLG : null};
  }
  @media (min-width: 1200px) {
    margin-top: ${(props: any) => props.mtXL};
    margin-bottom: ${(props: any) => props.mbXL};
    min-width: ${(props: any) => props.sizeXXL};
    height: ${(props: any) => props.sizeYXL ? props.sizeYXL : null};
    font-size: ${(props: any) => props.fontSizeXL ? props.fontSizeXL : null};
  }
  &:hover {
    color: ${(props: any) => props.hoverTextColor};
    background: ${(props: any) => props.hoverBackground};
  }
`;
export const BtnLink = styled.span.attrs((props: any) => ({
  // we can define static props
  className: 'btn'
}))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${(props: any) => props.sizeX ? props.sizeX : null};
  height: ${(props: any) => props.sizeY ? props.sizeY : null};
  font-size: ${(props: any) => props.fontSize ? props.fontSize : null};
  letter-spacing: ${(props: any) => props.letterSpacing};
  padding-left: ${(props: any) => props.pl ? props.pl : '10px'};
  padding-right: ${(props: any) => props.pr ? props.pr : '10px'};
  text-transform: ${(props: any) => props.textTransform ? props.textTransform : 'unset'};
  border-radius: ${(props: any) => props.radius ? props.radius : '5px'};
  margin-top: ${(props: any) => props.mt};
  margin-bottom: ${(props: any) => props.mb};
  color: ${(props: any) => props.textColor};
  background: ${(props: any) => props.backgroundColor};
  transition: 0.4s;
  @media (min-width: 480px) {
    margin-top: ${(props: any) => props.mtXS};
    margin-bottom: ${(props: any) => props.mbXS};
    min-width: ${(props: any) => props.sizeXXS};
    height: ${(props: any) => props.sizeYXS};
    font-size: ${(props: any) => props.fontSizeXS};
  }
  @media (min-width: 575px) {
    margin-top: ${(props: any) => props.mtSM};
    margin-bottom: ${(props: any) => props.mbSM};
    min-width: ${(props: any) => props.sizeXSM};
    height: ${(props: any) => props.sizeYSM};
    font-size: ${(props: any) => props.fontSizeSM};
  }
  @media (min-width: 768px) {
    margin-top: ${(props: any) => props.mtMD};
    margin-bottom: ${(props: any) => props.mbMD};
    min-width: ${(props: any) => props.sizeXMD};
    height: ${(props: any) => props.sizeYMD};
    font-size: ${(props: any) => props.fontSizeMD};
  }
  @media (min-width: 992px) {
    margin-top: ${(props: any) => props.mtLG};
    margin-bottom: ${(props: any) => props.mbLG};
    min-width: ${(props: any) => props.sizeXLG};
    height: ${(props: any) => props.sizeYLG};
    font-size: ${(props: any) => props.fontSizeLG};
  }
  &:hover {
    color: ${(props: any) => props.hoverTextColor};
    background: ${(props: any) => props.hoverBackground};
  }
`;

export const ButtonLink = ({
  to,
  children,
  ...props
}: any) => {
  return (
    <a href={to ? to : '/#'}>
      <BtnLink {...props}>{children}</BtnLink>
    </a>
  );
};
