
// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from 'styled-components/macro';

export const Heading = styled.div`
  text-transform: ${(props: any) => props.textTransform ? props.textTransform : null};
  font-family: ${(props: any) => props.family};
  font-size: ${(props: any) => props.size};
  margin-top: ${(props: any) => props.mt};
  margin-bottom: ${(props: any) => props.mb};
  margin-left: ${(props: any) => props.ml};
  margin-right: ${(props: any) => props.mr};
  padding-top: ${(props: any) => props.pt};
  font-weight: ${(props: any) => props.weight};
  padding-bottom: ${(props: any) => props.pb};
  padding-left: ${(props: any) => props.pl};
  padding-right: ${(props: any) => props.pr};
  opacity: ${(props: any) => props.fontOpacity};
  color: ${(props: any) => props.fontColor};
  @media (min-width: 480px) {
    font-size: ${(props: any) => props.sizeXS};
    margin-top: ${(props: any) => props.mtXS};
    margin-bottom: ${(props: any) => props.mbXS};
    margin-left: ${(props: any) => props.mlXS};
    margin-right: ${(props: any) => props.mrXS};
    padding-top: ${(props: any) => props.ptXS};
    padding-bottom: ${(props: any) => props.pbXS};
    padding-left: ${(props: any) => props.plXS};
    padding-right: ${(props: any) => props.prXS};
  }
  @media (min-width: 575px) {
    font-size: ${(props: any) => props.sizeSM};
    margin-top: ${(props: any) => props.mtSM};
    margin-bottom: ${(props: any) => props.mbSM};
    margin-left: ${(props: any) => props.mlSM};
    margin-right: ${(props: any) => props.mrSM};
    padding-top: ${(props: any) => props.ptSM};
    padding-bottom: ${(props: any) => props.pbSM};
    padding-left: ${(props: any) => props.plSM};
    padding-right: ${(props: any) => props.prSM};
  }
  @media (min-width: 768px) {
    font-size: ${(props: any) => props.sizeMD};
    margin-top: ${(props: any) => props.mtMD};
    margin-bottom: ${(props: any) => props.mbMD};
    margin-left: ${(props: any) => props.mlMD};
    margin-right: ${(props: any) => props.mrMD};
    padding-top: ${(props: any) => props.ptMD};
    padding-bottom: ${(props: any) => props.pbMD};
    padding-left: ${(props: any) => props.plMD};
    padding-right: ${(props: any) => props.prMD};
  }
  @media (min-width: 992px) {
    font-size: ${(props: any) => props.fontSizLG};
    margin-top: ${(props: any) => props.mtLG};
    margin-bottom: ${(props: any) => props.mbLG};
    margin-left: ${(props: any) => props.mlLG};
    margin-right: ${(props: any) => props.mrLG};
    padding-top: ${(props: any) => props.ptLG};
    padding-bottom: ${(props: any) => props.pbLG};
    padding-left: ${(props: any) => props.plLG};
    padding-right: ${(props: any) => props.prLG};
  }
  @media (min-width: 1200px) {
    font-size: ${(props: any) => props.sizeXL};
    margin-top: ${(props: any) => props.mtXL};
    margin-bottom: ${(props: any) => props.mbXL};
    margin-left: ${(props: any) => props.mlXL};
    margin-right: ${(props: any) => props.mrXL};
    padding-top: ${(props: any) => props.ptXL};
    padding-bottom: ${(props: any) => props.pbXL};
    padding-left: ${(props: any) => props.plXL};
    padding-right: ${(props: any) => props.prXL};
  } ;
`;
export const Paragraph = styled.p`
  text-transform: ${(props: any) => props.textTransform ? props.textTransform : null};
  font-family: ${(props: any) => props.family};
  margin-top: ${(props: any) => props.mt};
  margin-bottom: ${(props: any) => props.mb};
  margin-left: ${(props: any) => props.ml};
  margin-right: ${(props: any) => props.mr};
  padding-top: ${(props: any) => props.pt};
  font-weight: ${(props: any) => props.weight};
  padding-bottom: ${(props: any) => props.pb};
  padding-left: ${(props: any) => props.pl};
  padding-right: ${(props: any) => props.pr};
  opacity: ${(props: any) => props.fontOpacity};
  color: ${(props: any) => props.fontColor};
  font-size: ${(props: any) => props.size};
  @media (min-width: 480px) {
    margin-top: ${(props: any) => props.mtXS};
    margin-bottom: ${(props: any) => props.mbXS};
    margin-left: ${(props: any) => props.mlXS};
    margin-right: ${(props: any) => props.mrXS};
    padding-top: ${(props: any) => props.ptXS};
    padding-bottom: ${(props: any) => props.pbXS};
    padding-left: ${(props: any) => props.plXS};
    padding-right: ${(props: any) => props.prXS};
    font-size: ${(props: any) => props.sizeXS};
  }
  @media (min-width: 575px) {
    margin-top: ${(props: any) => props.mtSM};
    margin-bottom: ${(props: any) => props.mbSM};
    margin-left: ${(props: any) => props.mlSM};
    margin-right: ${(props: any) => props.mrSM};
    padding-top: ${(props: any) => props.ptSM};
    padding-bottom: ${(props: any) => props.pbSM};
    padding-left: ${(props: any) => props.plSM};
    padding-right: ${(props: any) => props.prSM};
    font-size: ${(props: any) => props.sizeSM};
  }
  @media (min-width: 768px) {
    font-size: ${(props: any) => props.sizeMD};
    margin-top: ${(props: any) => props.mtMD};
    margin-bottom: ${(props: any) => props.mbMD};
    margin-left: ${(props: any) => props.mlMD};
    margin-right: ${(props: any) => props.mrMD};
    padding-top: ${(props: any) => props.ptMD};
    padding-bottom: ${(props: any) => props.pbMD};
    padding-left: ${(props: any) => props.plMD};
    padding-right: ${(props: any) => props.prMD};
  }
  @media (min-width: 992px) {
    font-size: ${(props: any) => props.sizeLG};
    margin-top: ${(props: any) => props.mtLG};
    margin-bottom: ${(props: any) => props.mbLG};
    margin-left: ${(props: any) => props.mlLG};
    margin-right: ${(props: any) => props.mrLG};
    padding-top: ${(props: any) => props.ptLG};
    padding-bottom: ${(props: any) => props.pbLG};
    padding-left: ${(props: any) => props.plLG};
    padding-right: ${(props: any) => props.prLG};
  }
  @media (min-width: 1200px) {
    font-size: ${(props: any) => props.sizeXL};
    margin-top: ${(props: any) => props.mtXL};
    margin-bottom: ${(props: any) => props.mbXL};
    margin-left: ${(props: any) => props.mlXL};
    margin-right: ${(props: any) => props.mrXL};
    padding-top: ${(props: any) => props.ptXL};
    padding-bottom: ${(props: any) => props.pbXL};
    padding-left: ${(props: any) => props.plXL};
    padding-right: ${(props: any) => props.prXL};
  } ;
`;
