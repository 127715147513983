
// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l5-cont... Remove this comment to see the full error message
import Pone from "../../assets/image/png/l5-content-image.png";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l5-cont... Remove this comment to see the full error message
import Ptwo from "../../assets/image/png/l5-content-image.png";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l5-cont... Remove this comment to see the full error message
import Pthree from "../../assets/image/png/l5-content-image.png";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l5-cont... Remove this comment to see the full error message
import Pfour from "../../assets/image/png/l5-content-image.png";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l5-cont... Remove this comment to see the full error message
import Pfive from "../../assets/image/png/l5-content-image.png";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l5-cont... Remove this comment to see the full error message
import Psix from "../../assets/image/png/l5-content-image.png";

const portfolioData = [
  {
    image: Pone,
    titleSmall: "Agency Brand guide",
    title: "UX and UI, Design",
    id: "1",
    tagName: ["all", "design", "seo"],
  },
  {
    image: Ptwo,
    titleSmall: "Agency Brand guide",
    title: "UX and UI, Design",
    id: "2",
    tagName: ["all", "marketing", "development"],
  },
  {
    image: Pthree,
    titleSmall: "Agency Brand guide",
    title: "UX and UI, Design",
    id: "3",
    tagName: ["all", "design", "marketing"],
  },
  {
    image: Pfour,
    titleSmall: "Agency Brand guide",
    title: "UX and UI, Design",
    id: "4",
    tagName: ["all", "seo"],
  },
  {
    image: Pfive,
    titleSmall: "Agency Brand guide",
    title: "UX and UI, Design",
    id: "6",
    tagName: ["all", "design"],
  },
  {
    image: Psix,
    titleSmall: "Agency Brand guide",
    title: "UX and UI, Design",
    id: "6",
    tagName: ["all", "design"],
  },
];
export default portfolioData;
