import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #2d2d30;
  padding-bottom: 30px;
  padding-top: 30px;
  @media ${device.md} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
`;

const Fact = ({
  numbers,
  numbersHeader
}: any) => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="dark">
      <Container>
        <Box className="text-center" pb={4}>
          <Title variant="h2" color="light">
            {numbersHeader}
          </Title>
        </Box>
        <Row>
          {numbers.map((number: any) => {
            return (
              <Col md="4" sm="6" className="mb-4 mb-md-0">
                <Box pr={4}>
                  <Title variant="h3" color="holzGreen" mb="20px!important">
                    {number.count}
                  </Title>
                  <Text color="lightShade">{number.description}</Text>
                </Box>
              </Col>
            );
          })}
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
