import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import { color, space, typography, shadow } from "styled-system";

const Paragraph = styled.div`
  & > p {
    margin-bottom: 0;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 38px;
    ${color};
    ${space};
    ${typography};
    ${shadow};
  }
`;

const ParagraphSmall = styled(Paragraph)`
  & > p {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 28px;
    ${color};
    margin: 0;
    /* ${space}; */
    ${typography};
    ${shadow};
  }
  & > p:not(:first-child) {
    padding-top: 1rem;
  }
`;

const TextWrapper = ({
  variant,
  ...props
}: any) => {
  let TextRender;
  switch (variant) {
    case "small":
      TextRender = ParagraphSmall;
      break;
    default:
      TextRender = Paragraph;
  }

  return <TextRender color="text" {...props} />;
};

export default TextWrapper;
